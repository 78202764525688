.header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.header__back {
  background: none;
  border: none;
  color: #984ae2;
  font-weight: 600;
  font-size: 14px;
}
